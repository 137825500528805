import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { CssGrid } from "../components/CssGrid"
import { Hero } from "../components/Hero"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { Button } from "../components/Button"
import { Content } from "../components/Content"
import { Subnavigation } from "../components/Subnavigation"

let SUBMENU = []

const News = () => (
  <StaticQuery
    query={graphql`
      query BlogAllNewsQuery {
        allWordpressWpNews(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              date(formatString: "DD, MMM YYYY")
              title
              slug
              excerpt
              categories {
                name
                slug
              }
            }
          }
        }
        submenu: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "media" } }
        ) {
          edges {
            node {
              items {
                title
                url
                target
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <CssGrid className="internal-page">
          <div>
            <Hero>
              <Content
                fillBackgroundWithImage
                fillBlockWithImage
                backgroundImage="about_ovolo_banner.jpg"
              >
                <h1>News</h1>
              </Content>
            </Hero>
            {data.submenu.edges.map(({ node }, i) => {
              SUBMENU = node.items
            })}
            <Subnavigation items={SUBMENU} />
            <Breadcrumbs categoryName="News" />
            <div className="container">
              <div className="row">
                <CssGrid>
                  <div className=" col col-xs-12 -col-md-3">Filters</div>
                  <div className="col col-xs-12 col-md-9">
                    <div className="blog-grids">
                      {data.allWordpressWpNews.edges.map(({ node }) => (
                        <div key={node.slug} className="grid">
                          <div className="entry-media">
                            {node.featured_media && (
                              <Link to={`/news/${node.slug}`}>
                                <Img
                                  src={
                                    node.featured_media.localFile
                                      .childImageSharp.sizes.src
                                  }
                                  sizes={
                                    node.featured_media.localFile
                                      .childImageSharp.sizes
                                  }
                                  alt="blog_image_one"
                                  className="img-fluid"
                                />
                              </Link>
                            )}
                          </div>
                          <div className="entry-body">
                            <span className="cat">
                              {node.categories &&
                                node.categories.map(
                                  category => `${category.name}, `
                                )}
                            </span>
                            <h3>
                              <Link
                                to={`/news/${node.slug}`}
                                dangerouslySetInnerHTML={{ __html: node.title }}
                              />
                            </h3>
                            <div
                              dangerouslySetInnerHTML={{ __html: node.excerpt }}
                            />
                            <div className="read-more-date">
                              <span className="date">{node.date}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Button size="small neue green">{"Load More"}</Button>
                  </div>
                </CssGrid>
              </div>
            </div>
          </div>
        </CssGrid>
      </Layout>
    )}
  />
)

export default News
